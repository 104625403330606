import React from 'react'
import {timeConvert} from '../../Utils/TimeUtils'

export default function ChatItem(props) {
    const user = JSON.parse(localStorage.getItem('MASuserData'))

    
  return (

    <ul  id="chatMain">
              { 
            props.data && props.data.map((item,index)=>{
                return(
                    <>
                       { (item.sender_id===user.id) 
                        ?
                        <>
                        <li class="clearfix">
                            <div class="message-data text-right"> <span class="message-data-time" >{timeConvert(item.created_at)}</span> &nbsp; &nbsp;</div>
                            <div class="message other-message float-right">{item.message}</div>
                        </li>
                        </>
                        :
                        <>
                        <li>
                            <div class="message-data">
                            <span class="message-data-name"></span> <span class="message-data-time">{timeConvert(item.created_at)}</span>
                            </div>
                            <div class="message my-message">{item.message}</div>
                        </li>                        
                        </>
                        
                    }
                    </>         
                )
            })}

    </ul>
  )
}
