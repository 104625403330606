import React from 'react'

export default function CaptureMonitoringComponents(props) {
    // console.log(props);
  return (
    <div className="body">
    <div className="tab-content">
        <div role="tabpanel" className="tab-pane active" id="official" aria-expanded="true">
        { 
            props.data && props.data.map((item,index)=>{
                return(
                    <>
                    <li className="list-group-item" onClick={()=>props.viewClick(index,item)}>
                        <p class="card-text">
                            <div>{item.message}<strong> {item.created_at}</strong>
                            </div>
                             {
                                (item.status==="Unalert") ? 
                                 <span class="label label-success m-1">Unalert</span>    
                                :
                                    (item.status==="Alert") ?
                                        <span class="label label-danger m-1">Alert</span>
                                         :
                                        <span class="label label-warning m-1">Processing</span>
                            }
                        </p>
                    </li>
                    </>         
                )
            })}
        </div>
    </div>
</div>
  )
}
