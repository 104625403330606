import React,{useEffect,useState,useCallback} from 'react'
import io from 'socket.io-client';
import {getSocketUrl} from '../../Socket/SocketDetails'
import $ from 'jquery'; 
import {getChatRoom} from '../../api/apiHandler'
import { useLocation } from 'react-router-dom';
import ChatItem from './ChatItem';

export default function Chat(params) {
    const {socket, ...others} = params;
    let data = useLocation();

    // // console.log("Data redirect",data.state)


    var receiverId=data.state.rId;
    var receiverType=data.state.rRole;
    var chatRoomId=data.state.chatId;
    var reciverName=data.state.rName;
    var [reciverStatus,setReciverStatus]=useState(data.state.rStatus);


    const user = JSON.parse(localStorage.getItem('MASuserData'))

    const [chatList,setChatList]=useState([])


    function roleGet(role){
        var roleData=role

        return roleData;
    }

//     useEffect(() => {
//       if (user == null) {
//       } else {
//         //   // console.log('getSocketUrl()', getSocketUrl() + "?user_id=" + user.id + "&user_type=" + roleGet(user.role));
//           const newSocket = io(getSocketUrl() + "?user_id=" + user.id + "&user_type=" + roleGet(user.role), { reconnect: false });
//         //   // console.log('newSocket', newSocket);
//           setSocket(newSocket);
//       }
//       return () => {
//           if (socket != null) {
//               socket.close()
//               setSocket(null);
//           }
//       };
//   }, [setSocket]);

  useEffect( ()=>{
    getChatRoomDetailsApi(chatRoomId)
  },[]);

  useEffect(() => {
    if (socket !== null) {
        socket.on("send_message", (data) => {
            // console.log("send_message")
            if (data.message === "rest_keyword_send_msg_success") {
                document.getElementById("message").value = "";
               getChatRoomDetailsApi(data.data.chat_room_id)
            } else if (data.message === "rest_keyword_message_receive_success") {
                //let userRoomData = JSON.parse(localStorage.getItem("chatRoom"))
                if (chatRoomId== data.data.chat_room_id) {
                 getChatRoomDetailsApi(data.data.chat_room_id)
                }
            }

        });

        socket.on("change_group_info", (data) => {
            // console.log("change_group_info")
            // console.log("<p class='message'>" + JSON.stringify(data) + "</p>")
        });

        socket.on("message_delivered", (data) => {
            // console.log("Message Deliverd")
            // console.log("<p class='message'>" + JSON.stringify(data) + "</p>")
        });

        socket.on("message_read", (data) => {
            // console.log("message_read")
            // console.log("<p class='message'>" + JSON.stringify(data) + "</p>")
        });

        socket.on("live", (data) => {
            // console.log("live")
           // onlineFriendList()

           if(data.message==="rest_keyword_message_user_offline" || data.message==="rest_keyword_message_user_online"){
            if(data.data.user_id==""+receiverId){
                // console.log("<p class='message'>" + JSON.stringify( ) + "</p>")
                // console.log("CHange Data Offline")
                setReciverStatus(data.data.chat_status)
            }
           }
        })

        socket.on("send_quote", (data) => {
            // console.log("send_quote")
            // console.log("<p class='message'>" + JSON.stringify(data) + "</p>")
        });

        socket.on("get_quote_list", (data) => {
            // console.log("get_quote_list")
            // console.log("<p class='message'>" + JSON.stringify(data) + "</p>")
        });

        socket.on("rest_keyword_message_user_offline", (data) => {
            // console.log("rest_keyword_message_user_offline")
            // console.log("<p class='message'>" + JSON.stringify(data) + "</p>")
        });
        socket.on("chat_list_refresh", (data) => {
            // console.log("chat_list_refresh")
            //onlineFriendList()
            // console.log("<p class='message'>" + JSON.stringify(data) + "</p>")
        });
    }
    return () => {
    }
}, [socket]);

      function getChatRoomDetailsApi(roomId){
        // console.log("Chat Room Data",roomId);
        getChatRoom({"chat_room_id":roomId}).then((resposnse)=>{
        //   // console.log(resposnse);
          if(resposnse.data.code==1){
            setChatList([])
            setChatList(resposnse.data.data)

            setTimeout(() => {
                $("html,body").animate({scrollTop: $('ul#chatMain li:last').offset().top - 30});
            }, 300);

          }else{
            setChatList([])
          }
      });

      }
      
      const sendMessage = ()=>{
        // console.log("Call Send Message");
        var messageData=document.getElementById("message").value;
        // console.log(messageData);
    
        if (messageData.trim() !== "") {
            var msgSend = '{"school_id":"0","sender_id":' + user.id + ',"sender_type":"' + roleGet(user.role) + '","receiver_id":"' + receiverId+ '","receiver_type":"' + receiverType + '","message":"' + messageData.trim() + '"}'
            // console.log("msgSend", msgSend)
            socket.emit('send_message', msgSend);
            }

      }

  return (
          <section className="content chat-app">
    <div className="container-fluid">
        <div className="row clearfix">
            <div className="chat">
                <div className="chat-header clearfix">
                    <div className="chat-about">
                        <div className="chat-with">{reciverName}</div>
                        <div className="chat-num-messages">
                        {
                        (reciverStatus === "online") ?
                                            <>
                                            <div class="about">
                                                <div class="status"> <i class="zmdi zmdi-circle online"></i> online </div>
                                            </div>
                                            </>
                                            :
                                            <>
                                            <div class="about">
                                                <div class="status"> <i class="zmdi zmdi-circle offline"></i> offline </div>
                                            </div>
                                            </>                                        
                        }
                        </div>
                    </div>                    
                </div>
                <div className="chat-history" id="chatList">
                {<ChatItem data={chatList}/>}
                </div>
                <div className="chat-message clearfix">
                    <div className="form-group">
                        <div className="form-line">
                            <input type="text" id="message" className="form-control" placeholder="Comments"/>
                        </div>
                    </div>
                    <button className="btn btn-raised btn-default" onClick={()=>sendMessage()}>Send</button>
                </div>
                <div id="plist" className="people-list">
                </div>
            </div>           
        </div>
    </div>
    </section>
  )
}
