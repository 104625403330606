import moment from "moment";
import React, { Component, useEffect, useState } from "react";
import Select from "react-select";
import { searchData, createReportSearchData,getDownloadData } from "../../api/apiHandler";
import { ceptureMonitoringData } from '../../api/apiHandler'
import $ from "jquery";
// import { Checkbox } from "pretty-checkbox-react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import '../../config/global'

export default function Search(props) {
    const searchData = [
        { label: "Chat", value: "chat" },
        { label: "Comment", value: "comment" },
        { label: "Post", value: "post" },
    ];
    const [selectedSearch, setSelectedRole] = useState({
        label: "Post",
        value: "post",
    });
    const [user_id, setUserId] = useState("");
    const [post_id, setPostID] = useState("");
    const [comment_id, setCommentID] = useState("");
    const [sender_id, setSenderID] = useState("");
    const [receiver_id, setReceiverID] = useState("");
    const [start_date, setStartDate] = useState("");
    const [end_date, setEndDate] = useState("");
    const [maxEndDate, setMaxEndDate] = useState(
        moment(new Date()).format("YYYY-MM-DD")
    );
    const [arrDataPost, setDataPost] = useState([]);
    const [arrDataComments, setDataComments] = useState([]);
    const [arrDataChat, setDataChat] = useState([]);
    const [search_text, setSearchText] = useState("");
    const [totalRecords, setTotalRecords] = useState("");
    const [schoolList, setSchoolList] = useState([])
    const [selectedSchool, setSelectedSchool] = useState([]);
    const [selectedSchoolValue, setSelectedSchoolValue] = useState("");

    var userSchoolIds = JSON.parse(localStorage.getItem("MASschoolListId"));
    var userSchoolList = JSON.parse(localStorage.getItem("MASschoolList"));

    const setUserSchoolList = () => {
        let list =[]
        userSchoolList.map((item, index) => {
            list.push({label:item.school_name,value:item.school_id})
        })
        setSchoolList(list);
    }

    // // console.log("rendered with userid", user_id);

    var navigate = useNavigate();
    var selectedData = [];

    const handleSubmit = () => {
        var limit = 500;

        if (selectedSearch.value === "All") {
            limit = 500;
        } else {
            limit = 1500;
        }

        let data = {
            user_id,
            post_id,
            comment_id,
            sender_id,
            receiver_id,
            start_date,
            end_date,
            search_data:
                selectedSearch.value == null ? "" : selectedSearch.value,
            search_text,
            limit: limit,
        };
        Object.keys(data).forEach((key) => {
            if (data[key] === "") {
                delete data[key];
            }
        });

        destroyTable();

        props.isLoader(true);
        searchData(data).then((response) => {
            if (response.data.code === 1) {
                setDataPost(response.data.data?.post);
                setDataComments(response.data.data?.comment);
                // // console.log(response.data.data.chat);
                setDataChat(response.data.data?.chat);
                setTotalRecords(response.data.data?.totalRecords)
                addDatable();
            }
            props.isLoader(false);
        });
    };

    useEffect(() => {
        setUserSchoolList();
    }, []);

    const addDatable = () => {
        destroyTable();
        $(document).ready(function () {
            var table = $("#mangeReport").DataTable({
                order: [[0, "desc"]],
                searching: false,
                paging: true,
                pageLength: 50,
                //bDestroy: true,
                //processing: true,
                dom: "Bfrtip",
                buttons: ["csv","copy",{
                    text: 'Capture',
                    action: function ( e, dt, node, config ) {
                        if ($('input[name="actionSelect"]:checked').length == 0) {
                            alert("Select entries to capture!");
                            return;
                        }
                        var table = $("#mangeReport").DataTable();
                        $('input[name="actionSelect"]:checked').each(function() {
                            var rowData = table.row( $(this).parents('tr') ).data();
                            //saveAction(JSON.parse($("#row_"+ this.value).val()), $('[name=selectSchoolData]').val());
                            saveAction(rowData, $('[name=selectSchoolData]').val());
                            //$(this).prop('disabled', true);
                        });
                        setTimeout(() => alert("Capture request received!") , 5000);
                        $('input[name="actionSelect"]').prop( "checked", false );
                    }}, {
                    text: 'Download Selected',
                    action: function ( e, dt, node, config ) {
                        if ($('input[name="actionSelect"]:checked').length == 0) {
                            alert("Select entries to download!");
                            return;
                        }
                        var table = $("#mangeReport").DataTable();
                        var selectedIds = "";
                        $('input[name="actionSelect"]:checked').each(function() {
                            var rowData = table.row( $(this).parents('tr') ).data();
                            if (rowData.id != "") {
                                if (selectedIds == "") {
                                    selectedIds = rowData.id;
                                } else {
                                    selectedIds = selectedIds + "," + rowData.id;
                                }
                            }
                        });
                        downloadData({'selected_ids' : selectedIds});
                        $('input[name="actionSelect"]').prop( "checked", false );
                    }}, {
                    text: 'Download All',
                    action: function ( e, dt, node, config ) {
                        props.isLoader(true);
                        downloadData();
                    }}
                ],
                serverSide: true,
                'columns': [
                    { data: 'created_at' },
                    { data: 'id', 
                        "render": function(data, type, row) {
                            if ($('[name=selectSchoolData]').val() == "chat") {
                                return data + ', Room ID - ' + row.chat_room_id;
                            } else {
                                return data;
                            }
                        }
                    },
                    { data: 'school_name' },
                    { data: 'description' },
                    { data: 'user_id'  , 
                        "render": function(data, type, row) {
                            var selected_type = $('[name=selectSchoolData]').val();
                            if ($('[name=selectSchoolData]').val() == "chat") {
                                return "Sender - " + row.sender_id + "\n Receiver - " + row.receiver_id;
                            }  else {
                                return data;
                            }
                        }
                    },
                    { "defaultContent": $('[name=selectSchoolData]').val() },
                    { data: 'is_delete', 
                        "render": function(data, type, row) {
                            if (data == 1) {
                                return "Deleted";
                            } else {
                                return "Not Deleted";
                            }
                        }
                    },
                    { data: 'id', 
                        "render": function(data, type, row) {
                            return '<button class="btn btn-raised btn-primary waves-effect" style="align-self: center; justify-content: center;">Save</button>';
                        }
                    },
                    { data: 'id', 
                        "render": function(data, type, row) {
                            return '<input type="checkbox" class="checkBoxMy" value="'+data+'" name="actionSelect">';
                            // + "<input type='hidden' value='"+JSON.stringify(row)+"' id='row_" + data + "' name='row_" + data + "'>";
                        }
                    }
                ],
                ajax: {
                    'url': global.GLOBAL_CONSTANT.API_URL + '/api/admin/common/search_data',
                    'type': 'POST',
                    'contentType': 'application/json; charset=utf-8',
                    
                    'data': function ( data ) {
                        var schoolList = "";
                        data.user_id = $("#userId").val();
                        data.post_id = $("#postID").val();
                        data.comment_id = $("#commentId").val();
                        data.sender_id = $("#senderId").val();
                        data.receiver_id = $("#receiverId").val();
                        data.start_date = $("#sDate").val();
                        data.end_date = $("#eDate").val();
                        data.search_data = $('[name=selectSchoolData]').val();
                        data.search_text = $("#searchText").val();
                        data.order_by = "desc";
                        $('input[name="selectSchool"]').each(function() {
                            var schoolId = $(this).val();
                            if (schoolId != "") {
                                if (schoolList == "") {
                                    schoolList = schoolId;
                                } else {
                                    schoolList = schoolList + "," + schoolId
                                }
                            }
                        });
                        if (schoolList != "") {
                            data.school_list = schoolList;
                        }
                        return JSON.stringify(data);                        
                    },
                    'beforeSend': function (request) {
                        props.isLoader(true);
                        request.setRequestHeader("api-key", 'n4lBiwpD7XqiqSYWQkczLCiGl5QBpPii');
                        request.setRequestHeader("accept-language", 'en');
                        request.setRequestHeader("admin-type", 'monitoring_staff');
                        request.setRequestHeader("Content-Type", 'text/plain');
                        request.setRequestHeader("offline-access", 'true');
                        request.setRequestHeader("token", localStorage.getItem("MAStoken"));
                    },
                    'dataFilter': function(data) {
                        props.isLoader(false);
                        var dataObj = JSON.parse(data);
                        var retObj = dataObj.data;
                        return JSON.stringify(retObj);
                    },
                }
            });
            
            $('#mangeReport tbody').on( 'click', 'button', function () {
                var rowData = table.row( $(this).parents('tr') ).data();
                /*var result = async saveAction(rowData, $('[name=selectSchoolData]').val());
                alert(result);
                $(this).prop('disabled', true);*/
                saveAction(rowData, $('[name=selectSchoolData]').val());
                setTimeout(() => alert("Capture request received!") , 5000);
                $(this).prop('disabled', true);
            });
        });
    };
    const destroyTable = () => {
        var table = $("#mangeReport").DataTable();
        table.destroy();
    };

    const onChangeSelection = (e, id, type) => {
        // console.log("Select=>", e.target.checked, id, type);

        if (e.target.checked) {
            selectedData.push({ type: type, id: id });
        } else {
            var filteredArray = selectedData.filter((e) => {
                if (e.type === type && e.id === id) {
                    return false;
                } else {
                    return true;
                }
            });

            selectedData = filteredArray;
        }

        // console.log("Selected List Final==>", selectedData);
    };


    const handleSchoolChange=(data)=>{
        setSelectedSchool(data);
    }

    /*const showNotesDailog = () => {
        if (selectedData.length === 0) {
            Swal.fire({
                title: "Cybersmarties",
                text: "Please select first",
                confirmButtonText: "Okay",
            });
        } else {
            Swal.fire({
                inputLabel: "Notes",
                inputPlaceholder: "Type your notes here...",
                inputAttributes: {
                    "aria-label": "Type your notes here",
                },
                input: "textarea",
                confirmButtonText: "Submit",
                inputValidator: (value) => {
                    if (!value) {
                        return "You need to write something!";
                    }
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    createSelectDataRepor(result.value);
                }
            });
        }
    };*/

    const createSelectDataRepor = (notes) => {
        // console.log("Call Create API==>", selectedData);
        createReportSearchData({
            report_data: selectedData,
            notes: notes,
        }).then((response) => {
            // console.log("response", response);
            if (response.data.code === 1) {
                // navigate(0)
            }
            props.isLoader(false);
        });
    };

    const saveAction = async (data, type)=>{

        var message = "";
        message = data.description;
        type = type[0].toUpperCase() + type.slice(1);

        let create_data = {
            "data_id":data.id,
            "data_type":type,
            "user_id":data.user_id,
            "school_id":data.school_id,
            "message":message,
            "school_name":data.school_name,
        }

        if (data.username) create_data.username = data.username;

        if (type === "Chat") {
            create_data.sender_id = data.sender_id;
            create_data.user_id = data.sender_id;
            create_data.sender_username = data.sender_username;
            create_data.receiver_id = data.receiver_id;
            create_data.receiver_username = data.receiver_username;
        }

        ceptureMonitoringData(create_data).then((response)=>{
            if (response.data.code === 1) {
                //alert(type + " is successfully captured!");
                //event.target.disabled = true;
            }
        })
    }

    const downloadData = (extraData)=>{
        var data = {}
        var schoolList = "";

        data.user_id = $("#userId").val();
        data.post_id = $("#postID").val();
        data.comment_id = $("#commentId").val();
        data.sender_id = $("#senderId").val();
        data.receiver_id = $("#receiverId").val();
        data.start_date = $("#sDate").val();
        data.end_date = $("#eDate").val();
        data.search_data = $('[name=selectSchoolData]').val();
        data.search_text = $("#searchText").val();
        data.order_by = "desc";
        $('input[name="selectSchool"]').each(function() {
            var schoolId = $(this).val();
            if (schoolId != "") {
                if (schoolList == "") {
                    schoolList = schoolId;
                } else {
                    schoolList = schoolList + "," + schoolId
                }
            }
        });
        if (schoolList != "") {
            data.school_list = schoolList;
        }

        if (!$.isEmptyObject(extraData)) {
            data = $.extend(data, extraData);
        }

        var type = data.search_data;
        getDownloadData(data).then((response)=>{
            var csvContent = "Created Date,Id,School Name,Data,User Id,Type,Status";
            var downloadData = response.data.data;
            $.each(downloadData, function(index, rowObject) {
                var idString = rowObject.id;
                var userString = rowObject.user_id;
                var isDeleted = "Not Deleted";

                if (rowObject.is_delete == 1) {
                    isDeleted = "Deleted";
                }

                if (type == "chat") {
                    userString = "Sender - " + rowObject.sender_id + " Receiver - " + rowObject.receiver_id;
                    idString = idString + ', Room ID - ' + rowObject.chat_room_id;
                }
                var rowString = rowObject.created_at + ',' + rowObject.id + ',' + rowObject.school_name 
                                + ',"' + rowObject.description.replaceAll('"', '""') + '",' +  rowObject.user_id + ',' 
                                + type + ',' + isDeleted;
                csvContent = csvContent + '\r\n' + rowString;
            });
            props.isLoader(false);
            // Create a blob
            var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            var url = URL.createObjectURL(blob);

            // Create a link to download it
            var pom = document.createElement('a');
            var filename = 'CyberSmarties-' + type + "-" + moment().format("YYYY-MM-DD_HH_mm") + '.csv';
            pom.href = url;
            pom.setAttribute('download', filename);
            pom.click();
        })
    }

    return (
        <section className="content home">
            <div className="container-fluid">
                <div className="card row m-l-10 m-t-10">
                    <div className="row clearfix">
                        <div className="col-lg-2 col-md-2 col-sm-2 m-t-15 m-l-20">
                            <div className="card row">
                                <label htmlFor="email">Select Type</label>
                                <Select
                                    isMulti={false}
                                    value={selectedSearch}
                                    onChange={(data) => {
                                        // console.log(data);
                                        setSelectedRole(data);
                                        //setUserId("");
                                        setPostID("");
                                        setCommentID("");
                                        setSenderID("");
                                        setReceiverID("");
                                        //setSearchText("");
                                    }}
                                    options={searchData}
                                    name="selectSchoolData" 
                                    id="selectSchoolData"
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                            <label htmlFor="email">User ID</label>
                            <div className="form-line">
                                <input
                                    type="number"
                                    placeholder="User ID"
                                    className="form-control"
                                    id="userId"
                                    name="userId"
                                    value={user_id}
                                    onChange={(e) => setUserId(e.target.value) }
                                />
                            </div>
                        </div>
                        {selectedSearch?.label == "Post" && (
                            <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                                <label htmlFor="email">Post ID</label>
                                <div className="form-line">
                                    <input
                                        type="number"
                                        placeholder="Post ID"
                                        className="form-control"
                                        id="postID"
                                        name="postID"
                                        value={post_id}
                                        onChange={(e) =>
                                            setPostID(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {selectedSearch?.label == "Comment" && (
                            <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                                <label htmlFor="email">Comment ID</label>
                                <div className="form-line">
                                    <input
                                        type="number"
                                        placeholder="Comment ID"
                                        className="form-control"
                                        id="commentId"
                                        name="commentId"
                                        value={comment_id}
                                        onChange={(e) =>
                                            setCommentID(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        {selectedSearch?.label == "Chat" && (
                            <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                                <label htmlFor="email">Sender ID</label>
                                <div className="form-line">
                                    <input
                                        type="number"
                                        placeholder="Sender ID"
                                        className="form-control"
                                        id="senderId"
                                        name="senderId"
                                        value={sender_id}
                                        onChange={(e) =>
                                            setSenderID(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {selectedSearch?.label == "Chat" && (
                            <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                                <label htmlFor="email">Receiver ID</label>
                                <div className="form-line">
                                    <input
                                        type="number"
                                        placeholder="Receiver ID"
                                        className="form-control"
                                        id="receiverId"
                                        name="receiverId"
                                        value={receiver_id}
                                        onChange={(e) =>
                                            setReceiverID(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                            <label htmlFor="email">Start Date</label>
                            <div className="form-line">
                                <input
                                    type="datetime-local"
                                    placeholder="Start Date"
                                    className="form-control"
                                    id="sDate"
                                    name="sDate"
                                    onChange={(e) => {
                                        setStartDate(e.target.value);
                                    }}
                                    // onChange={stat}
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                            <div className="form-line">
                                <label htmlFor="email">End Date</label>
                                <input
                                    type="datetime-local"
                                    placeholder="End Date"
                                    className="form-control"
                                    id="eDate"
                                    name="eDate"
                                    max={maxEndDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value);
                                    }}
                                    // min={1670928763}
                                    // value={formValues.email}
                                    // onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                            <label htmlFor="email">Search Text</label>
                            <div className="form-line">
                                <input
                                    type="text"
                                    placeholder="Search Text"
                                    className="form-control"
                                    id="searchText"
                                    name="searchText"
                                    value={search_text}
                                    onChange={(e) => setSearchText(e.target.value) }
                                />
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-2 m-t-15 m-l-20">
                            <div className="card row">
                                <label htmlFor="email">Select School</label>
                                <Select
                                    isMulti={true}
                                    placeholder="Select"
                                    value={selectedSchool}
                                    onChange={(data) => handleSchoolChange(data)}
                                    options={schoolList}
                                    name="selectSchool" 
                                    id="selectSchool"
                                />
                            </div>
                        </div>

                        <button
                            className="btn btn-raised btn-primary waves-effect"
                            onClick={addDatable}
                            style={{
                                alignSelf: "center",
                                justifyContent: "center",
                            }}
                        >
                            Search
                        </button>
                        <input type="checkbox" />
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="body">
                                <table
                                    id="mangeReport"
                                    className="table table-bordered table-striped table-hover dataTable js-exportable"
                                >
                                    <thead>
                                        <tr>
                                            <th>Created Date</th>
                                            <th>Id</th>
                                            <th>School Name</th>
                                            <th>Data</th>
                                            <th>User Id</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th>Capture</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Created Date</th>
                                            <th>Id</th>
                                            <th>School Name</th>
                                            <th>Data</th>
                                            <th>User Id</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th>Capture</th>
                                            <th></th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
