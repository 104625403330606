import React from "react";
// import paring from '../../Utils/SzudzikPair'

export default function MonitoringComponents(props) {
    return (
        <div className="body">
            <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="official" aria-expanded="true">
                    <div class="alert bg-blue">Click to Save or to View</div>
                    {props.data && props.data.map((item,index)=>{
                        return(
                            <>
                            {/* {// console.log(item)} */}
                            <li className="list-group-item">
                                <p class="card-text">
                                    <div>{item.message}<strong> {item.created_at}</strong>
                                    </div>
                                    <button type="button" class="btn btn-raised btn-primary waves-effect m-1" onClick={()=>props.viewClick(index,item)}>View</button>
                                    <button type="button" class="btn btn-raised btn-success waves-effect m-1" onClick={()=>props.saveClick(index,item)}>Save</button>
                                </p>
                            </li>
                            </>         
                        )
                    })}
                </div>
            </div>
        </div>
    );
}
