import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getAdminList,deleteAdmin,changeAdminStatus,createChatRoom} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';

export default function AdminListing(props) {

    const user = JSON.parse(localStorage.getItem('MASuserData'))


    const [myAdminList, setAdminList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getAdminList({"page":"1","limit":"1000","sort_by":"id","order_by":"desc","m_admin_id":user.created_by,"m_role":user.role}).then((resposnse)=>{
            // console.log(resposnse)
            if(resposnse.data.code==1){
                setAdminList(resposnse.data.data.list);
            }else{
                setAdminList([]);
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                order: [[0, 'desc']],
                  pageLength: 10,
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    let navigate = useNavigate(); 

    const redirectCreateAdmin = ()=>{
        let path ="/createMonitoringStaff"
        navigate(path)
    }


    function createChatRoomApi(data){

    

        props.isLoader(true)
        createChatRoom({"sender_id":user.id,"sender_type":roleGet(user.role),"receiver_type":roleGet(data.role),"receiver_id":data.id,"school_id":"0"}).then((resposnse)=>{
            // console.log(resposnse);
            if(resposnse.data.code==1){
                var item=resposnse.data.data;
                navigate("/chat",{state:{"chatId":item.chat_room_id,"rId":item.user_details.user_id,"rRole":roleGet(data.role),"rName":item.user_details.display_user_name,"rStatus":item.user_details.chat_status}})
            }
            props.isLoader(false)
        });
    }

    function roleGet(role){
        var roleData=role

        return roleData;
    }


    const getRole = (roleData)=>{
        let role=""
        if(roleData==="supervisor"){
            role="Supervisor"
        }else{
            role="Staff"
        }

        return role
    }



    if (myAdminList==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Monitoring Staff"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    </div>
                    <div className="body">
                        <table id="mangeUser" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Language</th>
                                    <th>Role</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Language</th>
                                    <th>Role</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>

                            { 
                            myAdminList && myAdminList.map((item,index)=>{
                                    return(
                                        <tr id={"userId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.username}</td>
                                            <td>{item.email}</td>
                                            <td>{item.country_code+" "+item.phone_number}</td>
                                            <td>{item.language}</td>
                                            <td>{getRole(item.role)}</td>
                                            <td className="text-right">
                                            <div className='action-btn'>      
                                            <a onClick={()=> {createChatRoomApi(item)}} className="btn btn-default col-green"><i className="zmdi zmdi-comment-list"></i></a>                  
                                            <Link to={`/monitoringStaffDetails/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-eye"></i></Link>
                                            </div>    
                                            </td>
                                        </tr>

                                        )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
